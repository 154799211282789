import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

export default function PrivacyPage() {
    return (
        <Layout>
            <Seo
                title="Privacy Policy"
                description="DigiClinic Privacy Policy"
            />
            <div className="inttop"></div>
            <div className="app-content">
                <div className="container privacy">
                    <h1>Privacy Policy</h1>
                    <p>
                        This privacy policy applies between you, the User of
                        this Website and DigiClinic, the owner and provider of
                        this Website. DigiClinic takes the privacy of your
                        information very seriously. This privacy policy applies
                        to our use of any and all Data collected by us or
                        provided by you in relation to your use of the Website.
                    </p>
                    <p>
                        This privacy policy should be read alongside, and in
                        addition to, our Terms and Conditions.
                    </p>
                    <h2>Data</h2>
                    <p>
                        Collectively all information that you submit to
                        DigiClinic via the Website. This definition
                        incorporates, where applicable, the definitions provided
                        in the Data Protection Laws;
                    </p>
                    <h2>Cookies</h2>
                    <p>
                        A cookie is a small file placed in your web browser that
                        collects information about your web browsing behaviour.
                        Use of cookies allows a website to tailor its
                        configuration to your needs and preferences. Cookies do
                        not access information stored on your computer or any
                        personal information (e.g. name, address, email address
                        or telephone number). Most web browsers automatically
                        accept cookies but you can choose to reject cookies by
                        changing your browser settings. This may, however,
                        prevent you from taking full advantage of our website.
                        Our website uses cookies to analyse website traffic and
                        help us provide a better website visitor experience. In
                        addition, cookies may be used to serve relevant ads to
                        website visitors through third party services such as
                        Google AdWords. These ads may appear on this website or
                        other websites you visit.
                    </p>
                    <h2>Disclosure of your Information</h2>
                    <p>
                        We may from time to time need to disclose certain
                        information, which may include your Personal Data, to
                        comply with a legal requirement, such as a law,
                        regulation, court order, subpoena, warrant, in the
                        course of a legal proceeding or in response to a law
                        enforcement agency request. Also, we may use your
                        Personal Data to protect the rights, property or safety
                        of www.digiclinic.com.au, our customers or third
                        parties. If there is a change of control in one of our
                        businesses (whether by merger, sale, transfer of assets
                        or otherwise) customer information, which may include
                        your Personal Data, could be disclosed to a potential
                        purchaser under a confidentiality agreement. We would
                        only disclose your information in good faith and where
                        required by any of the above circumstances.
                    </p>
                    <h2>Third Parties</h2>
                    <p>
                        We do not and will not sell or deal in personal or
                        customer information. We will never disclose your
                        personal details to a third party except the necessary
                        information required by providers of products or
                        services you have purchased or to protect the rights,
                        property or safety of www.digiclinic.com.au, our
                        customers or third parties or if required by law. We may
                        however use in a general sense without any reference to
                        your name, your information to create marketing
                        statistics, identify user demands and to assist it in
                        meeting customer needs generally. In addition, we may
                        use the information that you provide to improve our
                        website and services but not for any other use.
                    </p>
                    <h2>Security</h2>
                    <p>
                        We strive to ensure the security, integrity and privacy
                        of personal information submitted to our website, and we
                        periodically update our security measures in light of
                        current technologies.
                    </p>
                    <h2>Links</h2>
                    <p>
                        This website may contain links to other websites. These
                        links are meant for your convenience only. Links to
                        third party websites do not constitute sponsorship or
                        endorsement or approval of these websites. Please be
                        aware that we are not responsible for the privacy
                        practices of such other websites. We encourage our users
                        to be aware, when they leave our website, to read the
                        privacy statements of each and every website that
                        collects personally identifiable information. This
                        privacy policy applies solely to information collected
                        by this website.
                    </p>
                    <h1>Change in Privacy Policy</h1>
                    <p>
                        As we plan to ensure our privacy policy remains current,
                        this policy is subject to change. We may modify this
                        policy at any time, in our sole discretion and all
                        modifications will be effective immediately upon our
                        posting of the modifications on this website. Please
                        return periodically to review our privacy policy. If you
                        have any questions or concerns at any time about our
                        privacy policy or the use of your personal information,
                        please contact us at info@digiclinic.com.au and we will
                        respond within 48 hours.
                    </p>
                </div>
            </div>
        </Layout>
    );
}
